/* @font-face {
    font-family: Benzin-Regular;
    src: url(../public/assets/fonts/Benzin-Regular.ttf);
  }
  
  @font-face {
    font-family: Benzin-Bold;
    src: url(../public/assets/fonts/Benzin-Bold.ttf);
  }
  
  @font-face {
    font-family: Benzin-Medium;
    src: url(../public/assets/fonts/Benzin-Medium.ttf);
  }*/

@font-face {
  font-family: Benzin-Semibold;
  src: url(../public/assets/fonts/Benzin-Semibold.ttf);
}

@font-face {
  font-family: "Futura Md BT";
  src: url(../public/assets/fonts/FuturaPTMedium.otf);
}

@font-face {
  font-family: "Druk-Medium";
  src: url(../public/assets/fonts/Druk\ Wide\ Bold\ Regular.ttf);
}

@font-face {
  font-family: "Druk Wide Web";
  src: url(../public/assets/fonts/DrukWide-Bold.woff2);
}

@import url("https://fonts.cdnfonts.com/css/futura-md-bt");

.light {
  --v-background-base: #fff;
  --v-midground-base: rgba(18, 22, 26, 0.1);
  --v-text-base: #000;
  --v-text-grad: rgb(233, 105, 93);
  --v-textFaint-base: Black;
  --v-hover-base: rgb(233, 105, 93);
}

.dark {
  --v-background-base: #0e0e0e;
  --v-midground-base: rgba(13, 215, 255, 0.16);
  --v-text-base: #fff;
  --v-text-grad: #ccb43d;
  --v-textFaint-base: #adb8cc;
  --v-hover-base: #ff5c25;

  /* --v-outline-base: #db55c3;
	--v-midground-base: #18191c;
	--v-transition-base: all 0.2s linear;
	--v-navcolor-base: #95c83b;
	--v-primary-base: #db55c3;
	--v-hover-base: #8080801c; */
}

/* Reset CSS */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  outline: none;
}

#root {
  height: 100%;
}

html,
body {
  scroll-behavior: smooth;
  height: 100%;
  overflow-x: hidden;
  background: var(--v-background-base);
  color: var(--v-text-base);
}

/* Change text & background color */
a,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

button,
button:hover,
button:focus,
button:active {
  outline: none;
}

img {
  vertical-align: middle;
}

ul,
ol {
  padding-left: 0px;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Druk Wide Web";
}

p {
  font-family: "Futura Md BT";
  font-size: 14px;
  line-height: 20px;
  color: var(--v-textFaint-base);
}

.terms-conditions li {
  font-family: "Futura Md BT";
  font-size: 14px;
  line-height: 20px;
  color: var(--v-textFaint-base);
  margin: 10px;
}

.terms-conditions ul {
  list-style: none;
  margin-bottom: 15px;
}

.terms-conditions .ownership-flex {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

/* Social links css  */

.links-container {
  position: absolute;
  z-index: 15;
  bottom: 15px;
  left: 25px;
}

.link-indiv {
  margin-bottom: 10px;
}

.link {
  flex-direction: column;
}

.link a,
.link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link a {
  margin-bottom: 10px;
  border: 2px solid var(--v-text-base);
  border-radius: 50px;
  height: 40px;
  width: 40px;
  font-size: 1.2rem;
  transition: 0.5s ease-in-out;
  color: var(--v-text-base);
}

.link a:hover {
  transform: scale(1.4);
}

.mp3-container {
  position: relative;
  z-index: 5;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.equalizerr {
  position: absolute;
  z-index: 5;
  bottom: 13.25px;
  left: 11.25px;
  display: block;
  width: 5px;
  background-color: var(--v-text-base);
  height: 6px;
  text-align: center;
}

.equalizerr:after {
  height: 15px;
}

.equalizerr:after,
.equalizerr:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 6.15px;
  width: 5px;
  background-color: var(--v-text-base);
}

.equalizerr:before {
  height: 12px;
}

.equalizerr:after {
  left: 11.5px;
}

.equalizer {
  position: absolute;
  z-index: 5;
  bottom: 13.25px;
  left: 11.25px;
  display: block;
  width: 5px;
  background-color: var(--v-text-base);
  height: 2.5px;
}

.center-me {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--v-text-base);
  border-radius: 50%;
}

.equalizer {
  text-align: center;
}

.equalizer,
.equalizer:after,
.equalizer:before {
  animation: equalize 1.25s steps(25) 0s infinite;
}

.equalizer:after,
.equalizer:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 6.15px;
  height: 25px;
  width: 5px;
  background-color: var(--v-text-base);
}

.equalizer:before {
  animation-name: equalize2;
}

.equalizer:after {
  left: 11.5px;
  animation-name: equalize3;
}

@keyframes equalize {
  0% {
    height: 5px;
  }

  4% {
    height: 2.5px;
  }

  8% {
    height: 5px;
  }

  12% {
    height: 8.75px;
  }

  16% {
    height: 12.5px;
  }

  20% {
    height: 16.25px;
  }

  24% {
    height: 15px;
  }

  28% {
    height: 13.75px;
  }

  32% {
    height: 13.75px;
  }

  36% {
    height: 10px;
  }

  40% {
    height: 12.5px;
  }

  44% {
    height: 12.5px;
  }

  48% {
    height: 13.75px;
  }

  52% {
    height: 15px;
  }

  56% {
    height: 16.25px;
  }

  60% {
    height: 15px;
  }

  64% {
    height: 15px;
  }

  68% {
    height: 13.75px;
  }

  72% {
    height: 12.5px;
  }

  76% {
    height: 13.75px;
  }

  80% {
    height: 15px;
  }

  84% {
    height: 15px;
  }

  88% {
    height: 16.25px;
  }

  92% {
    height: 12.5px;
  }

  96% {
    height: 8.75px;
  }

  to {
    height: 5px;
  }
}

@keyframes equalize2 {
  0% {
    height: 15px;
  }

  4% {
    height: 16.25px;
  }

  8% {
    height: 15px;
  }

  12% {
    height: 15px;
  }

  16% {
    height: 13.75px;
  }

  20% {
    height: 13.75px;
  }

  24% {
    height: 13.75px;
  }

  28% {
    height: 15px;
  }

  32% {
    height: 15px;
  }

  36% {
    height: 16.25px;
  }

  40% {
    height: 16.25px;
  }

  44% {
    height: 16.25px;
  }

  48% {
    height: 13.75px;
  }

  52% {
    height: 10px;
  }

  56% {
    height: 7.5px;
  }

  60% {
    height: 10px;
  }

  64% {
    height: 12.5px;
  }

  68% {
    height: 13.75px;
  }

  72% {
    height: 16.25px;
  }

  76% {
    height: 15px;
  }

  80% {
    height: 15px;
  }

  84% {
    height: 13.75px;
  }

  88% {
    height: 13.75px;
  }

  92% {
    height: 11.25px;
  }

  96% {
    height: 13.75px;
  }

  to {
    height: 15px;
  }
}

@keyframes equalize3 {
  0% {
    height: 11.25px;
  }

  4% {
    height: 8.75px;
  }

  8% {
    height: 11.25px;
  }

  12% {
    height: 13.75px;
  }

  16% {
    height: 16.25px;
  }

  20% {
    height: 18.75px;
  }

  24% {
    height: 17.5px;
  }

  28% {
    height: 13.75px;
  }

  32% {
    height: 11.25px;
  }

  36% {
    height: 10px;
  }

  40% {
    height: 8.75px;
  }

  44% {
    height: 6.25px;
  }

  48% {
    height: 10px;
  }

  52% {
    height: 12.5px;
  }

  56% {
    height: 13.75px;
  }

  60% {
    height: 16.25px;
  }

  64% {
    height: 15px;
  }

  68% {
    height: 13.75px;
  }

  72% {
    height: 13.75px;
  }

  76% {
    height: 12.5px;
  }

  80% {
    height: 15px;
  }

  84% {
    height: 16.25px;
  }

  88% {
    height: 17.5px;
  }

  92% {
    height: 15px;
  }

  96% {
    height: 12.5px;
  }

  to {
    height: 11.25px;
  }
}

.center-me {
  position: relative;
  top: -10%;
  left: 0;
  width: 40px;
  height: 40px;
  transition: 0.5s ease-in-out;
}

.center-me:hover {
  transform: scale(1.4);
}

@media screen and (max-width: 920px) {
  .links-container {
    position: fixed;
    bottom: 15px;
    left: 25px;
  }
}

@media screen and (max-width: 630px) {
  .links-container {
    position: fixed;
    bottom: 0;
    left: 3px;
  }

  .link {
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

/* pre loader css  */

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--v-background-base);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.preloader img {
  width: 125px;
  padding-bottom: 25px;
  clip-path: inset(1px 1px);
  -webkit-clip-path: inset(1px 1px);
  outline: none;
}

.loader {
  display: block;
  width: 450px;
  height: 44px;
  border-radius: 8px;
  top: 45%;
  top: calc(50% - 10px);
  left: 25%;
  left: calc(50% - 75px);
  border: 1px solid var(--v-text-base);
}

.loader:before {
  content: "";
  display: block;
  background: #ccb43d;
  top: 0;
  left: 0;
  height: 44px;
  width: 0;
  border-radius: 8px;
  z-index: 0;
  opacity: 1;
  transform-origin: 100% 0;
  animation: loader 3.8s ease-in-out infinite;
}

@keyframes loader {
  0% {
    width: 0;
  }

  70% {
    width: 100%;
    opacity: 1;
  }

  90% {
    opacity: 1;
    width: 100%;
  }

  to {
    opacity: 1;
    width: 0;
  }
}

@keyframes rotate-0b6b8080 {
  0% {
    rotate: 0deg;
  }

  to {
    rotate: 1turn;
  }
}

@media (max-width: 767px) {
  .preloader img {
    width: 100px;
    padding-bottom: 25px;
  }

  .loader {
    display: block;
    width: 250px;
    height: 20px;
    border-radius: 50px;
  }
  .loader:before {
    height: 20px;
  }
}

/* NavBar Css  */

.main-header .navbar {
  background: transparent;
  position: absolute;
  z-index: 111;
  width: 100%;
  left: 0;
  right: 0;
}

.main-header .navbar .nav-link {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px;
  padding: 8px 16px;
  height: 24px;
  box-sizing: content-box;
  border-radius: 8px;
  text-transform: uppercase;
  backdrop-filter: blur(12px);
  background-color: transparent;
  overflow: hidden;
  transition: all 800ms ease 0s;
  cursor: pointer;
  color: var(--v-text-base);
  font-family: "Futura Md BT";
  font-size: 17px;

  /* font-family: Benzin-Medium;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--v-text-base);
    padding: 7px 14px !important;
    position: relative;
    transition: all .2s ease-in-out;
    border-radius: 20px;
    border-radius: 4px; */
}
.main-header .navbar .nav-link::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  height: 0px;
  width: 0px;
  border-radius: 100px;
  background-color: var(--v-hover-base);
  transition: all 800ms ease 0s;
}
.main-header .navbar .nav-link:hover,
.main-header .navbar .nav-link.active {
  color: #0e0e0e;
}
.main-header .navbar .nav-link:hover::before,
.main-header .navbar .nav-link.active::before {
  height: 100px;
  width: 200px;
}

.main-header .navbar-collapse.collapsing {
  transition: none !important;
}

@media (max-width: 992px) {
  .main-header .navbar-toggler {
    color: var(--v-background-base);
    font-size: 36px;
    border: none;
    background: none;
  }

  .home-sm .navbar-collapse {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100vh;
    background-color: var(--v-background-base);
    z-index: 111;
    display: block !important;
  }

  .main-header .navbar-collapse.show {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100vh;
    background-color: var(--v-background-base);
    z-index: 11;
  }

  .cross-btn {
    font-size: 28px;
    color: var(--v-text-base) !important;
  }

  .main-header .navbar-toggler {
    color: var(--v-text-base) !important;
    font-size: 36px;
    border: none;
    background: none;
  }

  .main-header .navbar-toggler:focus {
    box-shadow: none;
  }

  .main-header .navbar-nav {
    align-items: flex-start !important;
    flex-direction: column;
  }

  .main-header .navbar .nav-link::before,
  .main-header .navbar .nav-link::before {
    content: none;
  }
  .main-header .navbar .nav-link:hover,
  .main-header .navbar .nav-link.active {
    color: #0e0e0e;
    background: var(--v-hover-base);
  }
}

@media (max-width: 767px) {
  .main-header::after {
    content: "";
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    /* background-color: var(--v-midground-base); */
  }
  .main-header .navbar .nav-link {
    padding: 5px 16px;
    font-size: 15px;
  }

  .navbar-brand img {
    height: 30px;
  }
}

@media (max-width: 991px) {
  .main-header .navbar-toggler {
    color: #000;
    font-size: 36px;
    border: none;
    background: none;
  }

  .main-header .navbar-collapse.show {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100vh;
    background-color: #000;
    z-index: 11;
  }
}

.br-20 {
  border-radius: 20px;
}

h2.meta {
  font-family: "Druk Wide Web";
  font-size: 48px;
  line-height: 60px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--v-text-base);
}
h3.meta {
  font-family: "Druk Wide Web";
  font-size: 32px;
  line-height: 60px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--v-text-base);
}

h4.meta {
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  background: var(--v-text-grad);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.date {
  font-size: 14px;
}
.date-h {
  line-height: 40px !important;
}

@media (max-width: 767px) {
  h2.meta {
    font-size: 30px;
    margin-bottom: 15px !important;
  }
  h4.meta {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 0 !important;
  }
  .navbar-nav.gap-4 {
    gap: 15px !important;
  }
  .date {
    display: block;
  }
}

.soon {
  position: relative;
}

.soon span {
  position: absolute;
  border: 1px solid var(--v-hover-base);
  border-radius: 1px;
  padding: 2px 2px;
  font-family: "Inter";
  font-weight: 700;
  font-size: 8px;
  line-height: 5px;
  color: var(--v-hover-base);
  top: -10px;
  left: 0px;
}
.main-header .navbar .soon .nav-link,
.main-header .navbar .soon .nav-link:hover,
.main-header .navbar .soon .nav-link.active {
  background: transparent;
  color: rgba(255, 255, 255, 0.3) !important;
  transition: all 0.2s ease-in-out;
}
.light .main-header .navbar .soon .nav-link,
.light .main-header .navbar .soon .nav-link:hover,
.light .main-header .navbar .soon .nav-link.active {
  color: rgba(0, 0, 0, 0.4) !important;
}

.dark-logo {
  display: none;
}
.dark .dark-logo {
  display: block;
}

.light-logo {
  display: none;
}
.light .light-logo {
  display: block;
}

/* Home  */

.banner {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}
.banner img {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

/* About Css  */

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  grid-gap: 40px;
  align-items: stretch;
}

.team {
  display: flex;
  align-items: center;
  gap: 20px;
}

.team-img {
  border-radius: 16px;
  width: 212px;
  height: 212px;
}

.team-img img {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.team .team-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.team .name {
  font-family: "Futura Md BT";
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 5px;
  color: var(--v-text-base);
}

.team .rank h6 {
  font-family: "Druk Wide Web";
  font-size: 24px;
  display: inline-block;
  background: var(--v-text-grad);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.team ul {
  list-style: disc;
  padding-left: 25px;
}

.team ul li {
  font-family: "Futura Md BT";
  color: var(--v-textFaint-base);
  font-size: 14px;
  margin-top: 5px;
  list-style: disc;
}

.team-link {
  display: flex;
  align-items: center;
  gap: 15px;
  color: var(--v-hover-base);
}

.team-link i {
  color: var(--v-hover-base);
  font-size: 16px;
}

.team-container .grid {
  height: calc(100vh - 175px);
  overflow-y: auto;
}

.team-container .grid::-webkit-scrollbar {
  width: 0;
}

@media screen and (max-width: 767px) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }

  .grid.no-scroll {
    height: auto;
  }
  .team-img {
    border-radius: 16px;
    width: 150px;
    height: 150px;
  }

  .team {
    display: flex;
    align-items: start;
    gap: 20px;
    flex-direction: column;
  }

  .team .name {
    font-size: 15px;
    line-height: 10px;
  }

  .team .rank h6 {
    font-size: 18px;
    line-height: 23px;
  }
}

/* Roadmap  */

.container.roadmap {
  max-width: 1220px;
}

.roadmap-row {
  display: flex;
  border: 1px solid var(--v-text-base);
  border-radius: 200px;
}
.roadmap-column {
  padding: 30px 30px 30px 100px;
  width: 50%;
}

.roadmap-content {
  height: 100%;
}
.roadmap-content h4 {
  font-family: "Druk Wide Web";
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--v-text-grad);
  margin-bottom: 25px;
}

.roadmap-content ul li {
  font-family: "Futura Md BT";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--v-text-base);
  margin-bottom: 20px;
  list-style: none;
}
.roadmap-content ul li:last-child {
  margin-bottom: 0;
}

.roadmap-content ul li span {
  font-family: "Futura Md BT";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--v-text-grad);
  border: 1px solid var(--v-text-grad);
  padding: 5px 10px;
  border-radius: 30px;
}

.br-l {
  border-left: 1px solid var(--v-text-base);
}
.br-r {
  border-right: 1px solid var(--v-text-base);
}

@media (max-width: 767px) {
  .roadmap-row {
    flex-direction: column;
    border-radius: 50px;
  }
  .roadmap-column {
    padding: 0px;
    width: 100%;
  }
  .roadmap-content {
    padding: 30px;
  }
  .br-l {
    border-top: 1px solid var(--v-text-base);
    border-left: 0;
  }
  .br-r {
    border-bottom: 1px solid var(--v-text-base);
    border-right: 0;
  }
  .roadmap-content ul li span {
    font-size: 10px;
  }
  .roadmap-content ul li {
    font-size: 14px;
    margin-bottom: 14px;
  }
}

/* Vision Css  */

/* .container.vision {
    position: relative;
    height: 100vh;
    width: 100%;
    scroll-snap-type: y mandatory;
    overflow: hidden scroll;
    scroll-behavior: smooth;
  } */
.container.vision::-webkit-scrollbar {
  width: 0;
}
.container.vision section,
.about .section1 {
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
}

.container.vision section:first-of-type,
.container.vision section:nth-of-type(2),
.container.vision section:nth-of-type(3),
.container.vision section:nth-of-type(4) {
  height: 100vh;
}

/* Archetype css  */

.ar-badges {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.ar-badges span {
  font-family: "Futura Md BT";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--v-text-base);
  border: 1px solid var(--v-text-base);
  padding: 3px 15px;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.ar-badges span.active,
.ar-badges span:hover {
  color: var(--v-hover-base);
  border: 1px solid var(--v-hover-base);
  transition: all 0.3s ease-in-out;
}

.ar-badges span.active-bg {
  color: #ccb43d;
  border: 1px solid #ccb43d;
  transition: all 0.3s ease-in-out;
}
.container.ar-container {
  max-width: 1200px;
}
.read-more {
  padding: 11px 20px 9px;
  gap: 10px;
  height: 40px;
  display: inline-block;
  background: var(--v-hover-base);
  color: #121619;
  white-space: nowrap;
  border-radius: 4px;
  font-family: "Benzin-Semibold";
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.read-more:hover {
  color: #121619;
}

/* FAQs  */

.faq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.faq .box-faq .accordion-item:first-of-type .accordion-button {
  background-color: transparent !important;
  color: var(--v-text-base);
  border: 0;
  padding-right: 80px;
}

.faq .box-faq .accordion-button:not(.collapsed)::after {
  background-image: url(../public/assets/images/down-arrow.svg);
  transform: rotate(-180deg);
}

.faq .box-faq .accordion-button:not(.collapsed)::before {
  background: var(--v-hover-base);
}

.faq .box-faq .accordion-button::before {
  flex-shrink: 0;
  width: 55px;
  height: 55px;
  position: absolute;
  content: "";
  right: 3px;
  background: rgba(255, 91, 37, 0.5);
  border: 1.5px solid rgba(233, 105, 93, 0.16);
  backdrop-filter: blur(40px);
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  z-index: 0;
}
.light .faq .box-faq .accordion-button::after {
  background-image: url(../public/assets/images/arrow-up-d.svg);
  transform: rotate(-180deg);
}
.light .faq .box-faq .accordion-button:not(.collapsed)::after {
  background-image: url(../public/assets/images/arrow-up-d.svg);
  transform: rotate(-0deg);
}

.faq .box-faq .accordion-button::before :hover {
  background: var(--v-hover-base);
}

.faq .box-faq .accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url(../public/assets/images/down-arrow.svg);
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  z-index: 9;
  position: absolute;
  right: 20px;
}

.faq .box-faq .accordion-button::after:hover {
  background-color: red;
}

.faq .box-faq .accordion-item {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px 0px;
}
.dark .faq .box-faq .accordion-item {
  border-bottom: 1px solid rgba(233, 105, 93, 0.16);
}

.faq .box-faq .accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.faq .box-faq .accordion-button {
  border-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 0rem;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
  font-family: "Druk Wide Web";
  font-size: 16px;
  line-height: 27px;
  color: var(--v-text-base);
  padding: 30px 60px 30px 0px !important;
}

.faq .box-faq .accordion-body {
  font-family: "Futura Md BT";
  font-size: 14px;
  line-height: 18px;
  padding-left: 0;
  color: var(--v-textFaint-base);
}

.faq .box-faq .accordion-body a {
  color: var(--v-hover-base);
}

.card-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.card-page .card-box {
  padding: 12px;
  background: rgba(0, 0, 0, 0.01);
  border: 1px solid rgba(173, 184, 204, 0.4);
  backdrop-filter: blur(40px);
  border-radius: 7px;
  height: 100%;
  transition: all linear 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dark .card-page .card-box {
  border: 1.5px solid 1px solid rgba(173, 184, 204, 0.4);
}

.card-page .card-box:hover {
  border: 1.5px solid var(--v-hover-base);
  cursor: pointer;
}

.card-page .card-box .upper-part {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 10px;
}

.card-page .card-box .upper-part .item-2 {
  flex: 1;
}

.ph-btn {
  background: #ff5c25;
  border-radius: 8px;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Futura Md BT";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #0e0e0e;
}

.in-bg {
  background: rgba(173, 184, 204, 0.04);
  border-radius: 8px;
}

.in-text {
  padding: 16px;
  font-family: "Futura Md BT";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.06em;
}
.in-text b {
  color: var(--v-text-base);
}
.in-text span {
  color: var(--v-textFaint-base);
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 100;
}
.in-text h5 {
  color: #ff5c25 !important;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 20px !important;
}
.in-text ul {
  margin-left: 16px;
  color: var(--v-textFaint-base);
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 100;
}

.card-page .card-box p {
  font-family: "Futura Md BT";
  font-size: 14px;
  line-height: 18px;
  color: var(--v-textFaint-base);
}

.grid-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  grid-gap: 40px;
  align-items: stretch;
}

.grid-cards h5 {
  font-family: "Druk Wide Web";
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  color: var(--v-text-base);
}
.grid-cards .fwb-300 {
  font-size: 12px !important;
}

.grid-cards h6 {
  font-family: "Futura Md BT";
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--v-textFaint-base);
}

.grid-cards .card-box {
  position: relative;
}

.grid-cards .card-box::after {
  content: "";
  position: absolute;
  right: -20px;
  height: 17px;
  width: 17px;
  top: 50%;
  transform: translate(10px, -50%);
}
body.light .grid-cards .card-box::after {
  background: url(../public/assets/images/arrow-right.svg);
}
body.dark .grid-cards .card-box::after {
  background: url(../public/assets/images/arrow-right-d.svg);
}

@media (max-width: 991px) {
  .faq .box-faq .accordion-button {
    line-height: 20px;
    padding-right: 50px;
  }

  .faq .box-faq .accordion-button::before {
    height: 40px;
    width: 40px;
  }

  .faq .box-faq .accordion-button::after {
    right: 13px;
  }
}

@media (max-width: 767px) {
  .grid-cards .card-box::after {
    content: "";
    position: absolute;
    right: 0px;
    height: 17px;
    width: 17px;
    top: auto;
    left: 0;
    bottom: -30px;
    margin: 0 auto;
    transform: rotate(90deg);
  }
}

.grid-cards .l-c::after {
  content: none;
}

/* Mint Css  */

.meta-input input {
  font-family: "Futura Md BT";
  width: 100%;
  height: 44px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 0 20px;
  letter-spacing: 2px;
  color: var(--v-textFaint-base);
  background: transparent;
}

.dark .meta-input input {
  border: 1px solid rgba(233, 105, 93, 0.16);
}

.scale-in-hor-right {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
  background: #ccb43d;
  height: 100%;
  width: 100%;
  -webkit-animation: scale-in-hor-right 0.6s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.2s both;
  animation: scale-in-hor-right 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.2s
    both;
}

@-webkit-keyframes scale-in-hor-right {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-hor-right {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

.scale-out-hor-left {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
  background: #ccb43d;
  height: 100%;
  width: 100%;
  -webkit-animation: scale-out-hor-left 0.6s
    cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.2s both;
  animation: scale-out-hor-left 0.6s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.2s
    both;
}

@-webkit-keyframes scale-out-hor-left {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes scale-out-hor-left {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

.fade-in {
  -webkit-animation: fade-in 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.8s both;
  animation: fade-in 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.8s both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-inn {
  -webkit-animation: fade-in 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.3s
    both;
  animation: fade-in 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.3s both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.meta-card {
  background: rgba(255, 255, 255, 0.01);
  border: 1.5px solid rgba(173, 184, 204, 0.4);
  backdrop-filter: blur(25px);
  border-radius: 8px;
}

.meta-img {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.meta-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.meta-text {
  padding: 15px 12px;
}
.meta-text h6 {
  font-family: "Futura Md BT";
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
}
.no-text-transform {
  text-transform: none !important;
}
